export function mergeObjects(obj1, obj2) {
    const merged = { ...obj1 };

    Object.entries(obj2).forEach(([key, value]) => {
        if (Array.isArray(value) && Array.isArray(merged[key])) {
            // Если значения - массивы, объединяем без дубликатов
            merged[key] = Array.from(new Set([...merged[key], ...value]));
        } else if (typeof value === "object" && value !== null && typeof merged[key] === "object") {
            // Если значения - объекты, рекурсивно объединяем
            merged[key] = mergeObjects(merged[key], value);
        } else {
            // Иначе, заменяем значение
            merged[key] = value;
        }
    });

    return merged;
}
