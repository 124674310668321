import Swiper from "swiper";

export function initClinicSpecialists() {
    const slider = document.querySelector(".clinic-specialists-2025__items .swiper");
    
    console.log('asdasdas');
    

    if (!slider) return;
    
    return new Swiper(slider, {
        slidesPerView: "auto",
        spaceBetween: 12,
        // slideClass: "clinic-specialists__slide", 
        navigation: {
            prevEl: `.clinic-specialists-2025__prev`,
            nextEl: `.clinic-specialists-2025__next`,
        },
        breakpoints: {
            768: {
                // allowTouchMove: true,
                spaceBetween: 24,
            },
        },
    });
}