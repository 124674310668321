import { initModules } from "./modules/index.js";
import { initAboutPageModules } from "./pages/about/index.js";
import { initMainPageModules } from "./pages/main/index.js";
import { initDetailPageModules } from "./pages/detail/index.js";
import { initSharedPageModules } from "./pages/shared/index.js";
import { initJournalPageModules } from "./pages/journal/index.js";
import { initTrainingPageModules } from "./pages/training/index.js";
import { initSkintestPageModules } from "./pages/skintest/index.js";
import { initUI } from "./ui/index.js";
import { initCareersPageModules } from "./pages/careers/index.js";
import { initEventInput } from "./pages/profile/index.js";
import {
    initCenterPageModules,
} from "./pages/skin-centers/index.js";
import { initOrderPageModules } from "./pages/order/index.js";
import { initQa } from "./pages/qa/index.js";
import { initPromotion } from "./pages/promotion/index.js";
import { initCartPageModules } from "./pages/shopping-cart/index.js";
import { initMasks } from "./utils/masks/index.js";
import {
    initDoctorsOnMap,
} from "./pages/doctors-in-map/index.js";
import { initLeadership } from "./pages/leadership/index.js";
import { initClinicPageModules } from "./pages/clinic/index.js";
import { initContactsPageModules } from "./pages/contacts/index.js";
import { initWhereToBuy } from "./pages/where-to-buy/index.js";
import { initSkinDiagnosticPageModules } from "./pages/skin-diagnostic/index.js";
import { initHorecaPageModules } from "./pages/horeca/index.js";
import { initMedicineProducts } from "./pages/medicine-products/index.js";
import { initProductOrderPage } from "./pages/product-order/index.js";
import { initInputDate } from "./ui/input-date/index.js";
import { initSurveyPageModules } from "./pages/survey/index.js";
import { initClinic2025PageModules } from "./pages/clinic-2025/index.js";

window.onload = () => {
    initUI();
    initModules();
    initMainPageModules();
    initAboutPageModules();
    initJournalPageModules();
    initEventInput();
    initTrainingPageModules();
    initDetailPageModules();
    initSharedPageModules();
    initSkintestPageModules();
    initCenterPageModules();
    initOrderPageModules();
    initCareersPageModules();
    initQa();
    initPromotion();
    initMasks();
    initCartPageModules();
    initLeadership();
    initDoctorsOnMap();
    initClinicPageModules();
    initContactsPageModules();
    initWhereToBuy();
    initSkinDiagnosticPageModules();
    initHorecaPageModules();
    initMedicineProducts();
    initProductOrderPage();
    initInputDate();
    initSurveyPageModules();
    initClinic2025PageModules();
};
