import { BaseYMap, BaseYMapV2 } from "../../ymap/index.js";
import { getCityCenterCoordinates } from "../../ymap/city-coordinates.js";
import { parseCenterInfoFromHTMLStr } from "../../../pages/skin-centers/util.js";
import { yclientsCodes } from "../../../services/api/endpoints/yclients/codes.js";

/**
 * на страницу с картой добавить тег
    <script src="https://api-maps.yandex.ru/1.1/index.xml" type="text/javascript"></script>
 *
 */
function BaseMap(center, container) {
    this.mapContainer = container.querySelector("#map");

    this.center = center;

    if (!this.mapContainer) return;

    try {
        this.map = new BaseYMapV2(this.mapContainer);
    } catch (error) {
        this.map = new BaseYMap(this.mapContainer);
    }
}

/**
 *
 * @param {{
 *  endpoint: () => Promise<Response>,
 * containerClass: string,
 * }} param0
 *
 * @returns {Promise<{
     * cities: string[],
* points: {
*      type:string,
*      features:{
*           type:string,
*           id:number,
*           city:string,
*           geometry: {
*               type:string,
*               coordinates:[x:number,y:number]
*           },
*           properties: {
*               balloonContentHeader: string,
*               balloonContentBody: string,
*               hintContent: string
*           },
*           options: {
*               preset: string
*           }
*      }[]
*
*      }
* }>}
 */
export async function initMap({
    endpoint,
    containerClass,
    beforeTabsInit,
}) {
    const container = document.querySelector(`.${containerClass}`).id === "CitiesTabs"
        ? document.querySelector(`.${containerClass}`).parentNode
        : document.querySelector(`.${containerClass}`);

    if (!container) return;

    const tabs = container.querySelector("#CitiesTabs");

    if (!tabs) return;
    let doctorsMap = null;

    /**
     *
     * @returns {Promise<import("../../../services/api/endpoints/map/index.js").MapResponse>}
     */
    async function getGeoPoints() {
        const response = await endpoint();

        if (response.status === 200) {
            return response.json();
        }
        throw new Error(response.statusText);
    }

    function addListeners() {
        tabs.addEventListener("city-change", (event) => {
            if (!doctorsMap) return null;
            doctorsMap.map.setCenter(event.detail.center, 11);

            const citiesPoints = document.querySelector(".cities-points");

            if (citiesPoints) {
                const prevCityPoin = document.querySelector(".cities-points__city--selected");

                if (prevCityPoin) prevCityPoin.classList.remove("cities-points__city--selected");

                const currentCityPoint = document.querySelector(`.cities-points__city[data-city="${event.detail.city}"]`);

                currentCityPoint.classList.add("cities-points__city--selected");
            }
        });
    }

    async function init() {
        try {
            const geoPoints = await getGeoPoints();

            const cities = geoPoints.cities;

            const tabList = document.querySelector(".cities-slider__wrapper");

            const citiesPoints = document.querySelector(".cities-points");

            cities.forEach((city, index) => {
                const coordinates = getCityCenterCoordinates(city);

                const newTab = `
                    <li data-center="${coordinates}" data-city="${city}" data-value=${index}
                        class="cities-slider__item tabs__tab-list-item${index === 0 ? "--selected" : ""}"
                    >
                        <button class="tabs__tab-button">
                            ${city}
                        </button>
                    </li>
                `;

                tabList.innerHTML += newTab;

                if (citiesPoints) {
                    const cityPoint = `
                        <div class="cities-points__city" data-city="${city}">
                            <h3 class="cities-points__heading">${city}</h3>
                            <ul class="cities-points__list">
                            </ul>
                        </div>
                    `;

                    citiesPoints.innerHTML += cityPoint;
                }
            });

            if (beforeTabsInit) {
                await beforeTabsInit(geoPoints);
            }

            doctorsMap = new BaseMap([0, 0], container);

            geoPoints.points.features.forEach((feature) => {
                const coordinates = feature.geometry.coordinates;

                const content = {
                    name: feature.properties.balloonContentHeader,
                    content: feature.properties.balloonContentBody,
                };

                const info = parseCenterInfoFromHTMLStr(feature.properties.balloonContentBody);


                try {
                    // try v2.1
                    ymaps.ready(() => {
                        doctorsMap.map.addMark(coordinates, content);

                        if (yclientsCodes[info.serviceType].routes) {
                            doctorsMap.map.addMultiRoute(
                                coordinates,
                                yclientsCodes[info.serviceType].routes,
                            );
                        }
                    });
                } catch (error) {
                    // v1.1
                    doctorsMap.map.addMark(coordinates, content);
                }

                if (citiesPoints) {
                    const cityPointList = document.querySelector(`.cities-points__city[data-city="${feature.city}"] .cities-points__list`);

                    const citiesPointsItem = `
                        <li class="cities-points__list-item">
                            <div class="cities-points__list-item-heading">
                                ${content.name}
                            </div>
                            ${content.content}
                        </li>
                    `;

                    cityPointList.innerHTML += citiesPointsItem;
                }
            });

            return geoPoints;
        } catch {
            return null;
        } finally {
            try {
                // try v2.1
                ymaps.ready(() => {
                    addListeners();
                });
            } catch (error) {
                // v1.1
                addListeners();
            }
        }
    }

    return init();
}
