import Swiper from "swiper";
import { EffectFade } from "swiper/modules";

export function initBannerTextSlider() {
    const swiper = new Swiper(".banner-text .swiper", {
        modules: [EffectFade],
        // loop: true,
        slidesPerView: 1,
        effect: "fade",
        autoHeight: true,
        fadeEffect: {
            crossFade: true,
        },
        speed: 800,
        autoplay: {
            delay: 9000,
        },
        navigation: {
            nextEl: ".banner-text .swiper-button-next",
            prevEl: ".banner-text .swiper-button-prev",
        },
    });
}
