/* eslint-disable no-restricted-syntax */
import Swiper from "swiper";
import { initSkinDiagnosticCenterCity } from "./city/index.js";

import { getQueryVariable } from "../../utils/query/get-query-variable.js";
import { initSkinDiagnosticCenterCities } from "./cities/index.js";
import { yclientsCodes } from "../../services/api/endpoints/yclients/codes.js";
import { API } from "../../services/api/endpoints/index.js";
import { parseCenterInfoFromHTMLStr } from "./util.js";
import { setSkinDiagnostictCenterTitles } from "./city/page-title.js";
import { BaseYMap, BaseYMapV2 } from "../../modules/ymap/index.js";
import { getCityCenterCoordinates } from "../../modules/ymap/city-coordinates.js";

const header = document.querySelector("header");
const skinDiagnosticCenterEl = document.querySelector(".skin-diagnostic-center");
const main = document.querySelector("main");
const skinCenterCityTemplateEl = document.createElement("div");
skinCenterCityTemplateEl.classList.add("skin-diagnostic-center__content-city");

/**
 * @returns {string[]}
 */
function getServiceTypesFromPath() {
    const pathArray = window.location.pathname.split("/");

    const firstPathElement = pathArray.shift() || pathArray.shift();

    const lastPathElement = firstPathElement === "skin-diagnostic-center" ? decodeURI(pathArray.pop()) || decodeURI(pathArray.pop()) : null;

    const serviceTypes = [];

    if (lastPathElement) {
        serviceTypes.push(...new Set(decodeURI(lastPathElement).split("|")));
    }

    return serviceTypes;
}
/**
 * @returns {string[]}
 */
function getServiceTypesFromQueryParams() {
    const queryAddress = getQueryVariable("address");

    const serviceTypes = [];

    if (queryAddress) {
        serviceTypes.push(...new Set(decodeURI(queryAddress).split("|")));
    }

    return serviceTypes;
}

/**
 * 
 * @returns {string[]}
 */
function getServiceTypesFromURL() {
    const serviceTypesFromQueryParams = getServiceTypesFromQueryParams();
    const serviceTypesFromPath = getServiceTypesFromPath();

    if (serviceTypesFromQueryParams.length) return serviceTypesFromQueryParams;

    return serviceTypesFromPath;
}

function generateSkinCenterLayout(serviceType) {
    const skinCenterCityEl = skinCenterCityTemplateEl.cloneNode(true);
    skinCenterCityEl.id = `center-${serviceType}`;
    skinCenterCityEl.style.display = "none";
    skinDiagnosticCenterEl.append(skinCenterCityEl);

    const map = skinCenterCityEl.querySelector("#map");

    map.id = `map-${serviceType}`;
}
function initMap(feature, serviceType, featuresToMark) {
    const mapContainer = main.querySelector(`#map-${serviceType}`);

    if (!mapContainer) return;

    const map = new BaseYMapV2(mapContainer, {
        center: getCityCenterCoordinates(feature.city),
        zoom: 11,
    });

    ymaps.ready(() => {
        map.setCenter(getCityCenterCoordinates(feature.city), 11);
    });

    const coordinates = feature.geometry.coordinates;

    ymaps.ready(() => {
        if (yclientsCodes[serviceType].routes) {
            map.addMultiRoute(
                coordinates,
                yclientsCodes[serviceType].routes,
            );
        }
    });

    featuresToMark.forEach((featureToMark) => {
        const coordinates = featureToMark.geometry.coordinates;

        const content = {
            name: featureToMark.properties.balloonContentHeader,
            content: featureToMark.properties.balloonContentBody,
        };
        ymaps.ready(() => {
            map.addMark(coordinates, content);
        });
    });
}

function handleLoading(isLoading) {
    const loaderWrapper = document.createElement("div");
    loaderWrapper.classList.add("loader-wrapper");

    loaderWrapper.style.width = "50wh";
    loaderWrapper.style.height = "50vh";
    loaderWrapper.style.display = "flex";
    loaderWrapper.style.alignItems = "center";
    loaderWrapper.style.justifyContent = "center";

    const loader = document.createElement("div");
    loader.classList.add("loader");
    loader.style.width = "32px";
    loader.style.height = "32px";
    loaderWrapper.append(loader);

    document.querySelectorAll(".skin-diagnostic-center__content-city").forEach((centerEl) => {
        centerEl.style.display = isLoading ? "none" : "flex";
    });

    if (isLoading) {
        skinDiagnosticCenterEl.append(loaderWrapper);
    } else {
        document.querySelector(".loader-wrapper").remove();
    }
}

/**
 *
 * @param {'list' | 'detail'} page
 */
function handleContentDisplay(page) {
    const listContentEl = skinDiagnosticCenterEl.querySelector(".skin-diagnostic-center__content-cities");
    const detailContentEl = skinDiagnosticCenterEl.querySelector(".skin-diagnostic-center__content-city");
    detailContentEl.remove();

    switch (page) {
        case "list":
            skinDiagnosticCenterEl.classList.add("skin-diagnostic-center--cities");
            break;
        case "detail":
            main.classList.add("body_header");
            header.classList.remove("header-full--secondary");
            skinDiagnosticCenterEl.classList.add("skin-diagnostic-center--city");
            listContentEl.remove();
            break;
        default: {
            break;
        }
    }
}

function initDoctorsSliders(serviceType) {
    return new Swiper(`${serviceType ? `#center-${serviceType}` : ""} .js-slider-doctors-list`, {
        slidesPerView: "auto",
        navigation: {
            prevEl: "#DoctorsSlider .slider-navigation-prev",
            nextEl: "#DoctorsSlider .slider-navigation-next",
        },
        breakpoints: {
            992: {
                allowTouchMove: true,
            },
        },
    });
}

async function initSkinCenterByServiceType(serviceType, feature) {
    try {
        await initSkinDiagnosticCenterCity(serviceType, feature);
        initDoctorsSliders(serviceType);
    } catch (error) {
        const centerEl = document.querySelector(`#center-${serviceType}`);

        if (centerEl) centerEl.remove();
    }
}

export async function initCenterPageModules() {
    if (!skinDiagnosticCenterEl) return;

    const detailContentEl = skinDiagnosticCenterEl.querySelector(".skin-diagnostic-center__content-city");
    skinCenterCityTemplateEl.innerHTML = detailContentEl.innerHTML;

    let isDetail = false;

    const correctServiceTypes = [];

    const serviceTypes = getServiceTypesFromURL();

    if (serviceTypes.length && !serviceTypes.includes("online")) {
        isDetail = true;
    }

    serviceTypes.forEach(async (serviceType) => {
        if (Object.keys(yclientsCodes).includes(serviceType) && serviceType !== "online") {
            correctServiceTypes.push(yclientsCodes[serviceType].serviceType);
        }
    });

    correctServiceTypes.forEach(generateSkinCenterLayout);

    if (isDetail && correctServiceTypes.length) {
        const response = await API.map.getCdkMap();

        const { points: { features } } = await response.json();

        handleContentDisplay("detail");
        handleLoading(true);
        await correctServiceTypes.reduce(async (promise, serviceType) => {
            await promise;
            const feature = features.find((f) => {
                const info = parseCenterInfoFromHTMLStr(f.properties.balloonContentBody);
                return info.serviceType === serviceType;
            });
            return initSkinCenterByServiceType(serviceType, feature);
        }, Promise.resolve());

        handleLoading(false);

        correctServiceTypes.forEach((serviceType) => {
            const feature = features.find((f) => {
                const info = parseCenterInfoFromHTMLStr(f.properties.balloonContentBody);
                return info.serviceType === serviceType;
            });

            setSkinDiagnostictCenterTitles(feature.city, serviceType);
            initMap(feature, serviceType, [...features]);
        });
    } else {
        handleContentDisplay("list");
        await initSkinDiagnosticCenterCities(serviceTypes.includes("online"));
        initDoctorsSliders();
    }
}
