/* eslint-disable no-use-before-define */

import { initNestedListHorizontalWrapper } from "./horizontal.js";
import { isNestedListOpen, ShowedListHistory } from "./util.js";

/**
 *
 * @param {HTMLElement} listWrapper element with class "nested-list__wrapper"
 */
function initNestedListWrapper(listWrapper) {
    if (!listWrapper.classList.contains("nested-list__wrapper")) return;

    const {
        showedListsHistory,
        appendShowedListsHistory,
        handleHistoryBack,
        handleHistoryReset,
    } = ShowedListHistory();

    const nestedLists = listWrapper.querySelectorAll(":scope > .nested-list:not(.nested-list--horizontal)");

    const backButton = listWrapper.querySelector(":scope > .nested-list__back");

    /**
     *
     * @param {string | null} title
     */
    function changeBackButtonTitle(title) {
        if (!backButton) return;

        if (title) {
            backButton.classList.remove("nested-list__back--hidden");
            const backButtonTitle = backButton.querySelector(":scope > .nested-list__back-title");
            backButtonTitle.innerText = title;
        } else {
            backButton.classList.add("nested-list__back--hidden");
        }
    }

    function handleBack() {
        handleHistoryBack((lastShowedList) => {
            closeNestedList(lastShowedList);
        });
    }

    /**
     *
     * @param {HTMLElement} nestedListElement element with class "nested-list"
     */
    function closeNestedList(nestedListElement) {
        if (!nestedListElement) return;

        if (!isNestedListOpen(nestedListElement)) return;

        const showedListIndex = showedListsHistory.findIndex((l) => l === nestedListElement);

        showedListsHistory.splice(showedListIndex, 1);

        nestedListElement.style.animation = "slideOut .6s ease-in-out";
        nestedListElement.classList.remove("nested-list--open");
        nestedListElement.style.display = "block";
        nestedListElement.style.translate = 0;

        function animEnd() {
            nestedListElement.style.display = null;
            nestedListElement.style.translate = null;
            nestedListElement.removeEventListener("animationend", animEnd);
        }

        nestedListElement.addEventListener("animationend", animEnd);

        listWrapper.classList.add("nested-list__wrapper--scrollbar-hide");

        const parentNode = nestedListElement.parentElement;

        const parentList = nestedListElement.parentElement.parentElement.parentElement;

        if (parentList && parentList.classList.contains("nested-list")) {
            parentList.classList.remove("nested-list--child-open");
            parentNode.classList.remove("nested-list__node--child-open");

            changeBackButtonTitle(parentList.getAttribute("data-node-title"));
        } else {
            changeBackButtonTitle(null);
        }
    }

    /**
     *
     * @param {HTMLElement} nodeElement element with class "nested-list__node"
     */
    function closeAllNodesOnNodeLevel(nodeElement) {
        if (!nodeElement.classList.contains("nested-list__node")) return;

        const parentList = nodeElement.parentElement.parentElement;

        if (!parentList || !parentList.classList.contains("nested-list")) return;

        const nestedListWrapper = parentList.parentElement;

        let openedNestedLists = [];

        if (nestedListWrapper === listWrapper) {
            openedNestedLists = nestedListWrapper.querySelectorAll(".nested-list__node--collapsible .nested-list--open");
        } else {
            openedNestedLists = parentList.querySelectorAll(".nested-list__node--collapsible .nested-list--open");
        }

        if (openedNestedLists.length) {
            openedNestedLists.forEach(closeNestedList);
        }
    }

    function handleReset() {
        handleHistoryReset(() => {
            nestedLists.forEach((nestedList) => closeNestedList(nestedList));
        });
    }

    /**
     *
     * @param {HTMLElement} nestedListElement element with class "nested-list"
     */
    function openNestedList(nestedListElement) {
        if (!nestedListElement) return;

        if (isNestedListOpen(nestedListElement)) return;

        const parentNode = nestedListElement.parentElement;

        parentNode.classList.add("nested-list__node--child-open");

        const parentList = nestedListElement.parentElement.parentElement.parentElement;

        if (parentList && parentList.classList.contains("nested-list")) {
            parentList.scrollTo({ top: 0 });
            parentList.classList.add("nested-list--child-open");
        }

        repositionNestedList(nestedListElement);

        if (window.innerWidth < 992) {
            nestedListElement.style.animation = "slideIn .6s ease-in-out";
        } else {
            nestedListElement.style.animation = "slideInMD .6s ease-in-out";
        }

        listWrapper.classList.add("nested-list__wrapper--scrollbar-hide");

        nestedListElement.onanimationend = () => {
            listWrapper.classList.remove("nested-list__wrapper--scrollbar-hide");
        };

        nestedListElement.classList.add("nested-list--open");

        appendShowedListsHistory(
            nestedListElement,
            (el) => {
                const title = el.parentNode.querySelector(".nested-list__node-content").innerText;
                changeBackButtonTitle(title);
            },
        );
    }

    function repositionNestedList(nestedListElement) {
        const isMobile = window.innerWidth < 992;

        if (!nestedListElement) return;

        if (isMobile && nestedListElement.style.left !== "0px") {
            nestedListElement.style.left = 0;
            return;
        }

        const parentList = nestedListElement.parentElement.parentElement.parentElement;

        if (parentList && parentList.classList.contains("nested-list")) {
            const parentRect = parentList.getBoundingClientRect();
            const listsMargin = 32;
            const minListsMargin = 24;
            const openedLists = listWrapper.querySelectorAll(".nested-list--open");

            if (isMobile) {
                openedLists.forEach((nl) => {
                    nl.style.left = null;
                    nl.style.minWidth = null;
                    nl.style.maxWidth = null;
                });
                nestedListElement.style.left = null;
                nestedListElement.style.minWidth = null;
                nestedListElement.style.maxWidth = null;
                return;
            }


            const listWidth = parentRect.width;

            const maxListInWrapper = Math.floor((listWrapper.clientWidth - listWidth) / listWidth);

            if (openedLists.length + 1 >= maxListInWrapper) {
                openedLists.forEach((nl) => {
                    nl.style.left = `${listWidth + minListsMargin}px`;
                    nl.style.minWidth = `275px`;
                    nl.style.maxWidth = `275px`;
                });
                nestedListElement.style.left = `${listWidth + minListsMargin}px`;
                nestedListElement.style.minWidth = `275px`;
                nestedListElement.style.maxWidth = `275px`;
            } else {
                nestedListElement.style.left = `${listWidth + listsMargin}px`;
            }
        }
    }

    /**
     *
     * @param {HTMLElement} listElement element with class "nested-list"
     */
    function initNestedList(listElement) {
        if (!listElement.classList.contains("nested-list")) return;

        const nodeElements = listElement.querySelectorAll(".nested-list__node");

        function nodeElementClick(event) {
            event.preventDefault();
            event.stopPropagation();

            const nodeElement = event.currentTarget;

            if (!nodeElement) return;

            if (!nodeElement.classList.contains("nested-list__node")) return;

            const nestedList = nodeElement.querySelector(".nested-list");

            if (!nestedList) return;

            if (isNestedListOpen(nestedList)) closeNestedList(nestedList);
            else {
                closeAllNodesOnNodeLevel(nodeElement);
                openNestedList(nestedList);
            }
        }

        nodeElements.forEach((nodeElement) => {
            const nestedList = nodeElement.querySelector(".nested-list");

            if (nestedList) {
                // collapsible - modifier which adds arrow icon to node item
                nodeElement.classList.add("nested-list__node--collapsible");

                const nodeContentElement = nodeElement.querySelector(".nested-list__node-content");

                if (!nodeContentElement) throw new Error('nested-list__node must contain element with class "nested-list__node-content"');

                const nodeTitle = nodeContentElement.querySelector(".nested-list__node-title");

                if (!nodeTitle) throw new Error('nested-list__node-content must contain element with class "nested-list__node-title"');

                // data-node-title - attr which the backButton can take to show level title
                nestedList.setAttribute("data-node-title", nodeTitle.innerText);

                // remove nested list bubbling to node click (rm close on nested list click)
                nestedList.addEventListener("click", (event) => {
                    event.stopPropagation();
                });

                window.addEventListener("resize", () => repositionNestedList(nestedList));

                nodeElement.addEventListener("click", nodeElementClick);
            }
        });
    }

    if (backButton) {
        backButton.addEventListener("click", handleBack);
        backButton.addEventListener("dblclick", handleReset);
        backButton.classList.add("nested-list__back--hidden");
    }

    nestedLists.forEach((nestedList) => initNestedList(nestedList));
}

export function initNestedLists() {
    const nestedListWrappersHtml = document.querySelectorAll(".nested-list__wrapper");

    nestedListWrappersHtml.forEach((listWrapper) => {
        initNestedListWrapper(listWrapper);
        initNestedListHorizontalWrapper(listWrapper);
    });
}
