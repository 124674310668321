/**
 *
 * @param {HTMLElement} nestedList element with class "nested-list or nested-list--open"
 */
export function isNestedListOpen(nestedList) {
    return nestedList.classList.contains("nested-list--open");
}

export function ShowedListHistory() {
    const showedListsHistory = [];

    /**
     *
     * @param {HTMLElement} listElement element with class "nested-list--open"
     * @param {(listElement:HTMLElement) => void} callback
     */
    function appendShowedListsHistory(listElement, callback) {
        if (!listElement.classList.contains("nested-list--open")) return;

        showedListsHistory.push(listElement);

        if (callback) {
            callback(listElement);
        }
    }

    /**
     *
     * @param {(lastShowedList:HTMLElement) => void} callback
     */
    function handleHistoryBack(callback) {
        const lastShowedListIndex = showedListsHistory.length - 1;

        const lastShowedList = showedListsHistory[lastShowedListIndex];

        if (lastShowedList && callback) {
            callback(lastShowedList);
        }
    }

    /**
    *
    * @param {() => void} callback
    */
    function handleHistoryReset(callback) {
        showedListsHistory.forEach(() => handleHistoryBack());

        if (callback) {
            callback();
        }
    }

    return {
        showedListsHistory,
        appendShowedListsHistory,
        handleHistoryBack,
        handleHistoryReset,
    };
}
