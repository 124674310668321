import { isNestedListOpen, ShowedListHistory } from "./util.js";

/**
 *
 * @param {HTMLElement} listWrapper element with class "nested-list__wrapper"
 */
export function initNestedListHorizontalWrapper(listWrapper) {
    const {
        showedListsHistory,
        appendShowedListsHistory,
        handleHistoryBack,
        handleHistoryReset,
    } = ShowedListHistory();

    const nestedList = listWrapper.querySelector(":scope > .nested-list.nested-list--horizontal");

    const backButton = listWrapper.querySelector(":scope > .nested-list__back");

    function changeBackButtonTitle(title) {
        if (!backButton) return;

        if (title) {
            backButton.classList.remove("nested-list__back--hidden");
            const backButtonTitle = backButton.querySelector(":scope > .nested-list__back-title");
            backButtonTitle.innerText = title;
        } else {
            backButton.classList.add("nested-list__back--hidden");
        }
    }

    /**
     *
     * @param {HTMLElement} nestedListElement element with class "nested-list"
     */
    function openNestedList(nestedListElement) {
        if (!nestedListElement) return;

        if (isNestedListOpen(nestedListElement)) return;

        const parentNode = nestedListElement.parentElement;

        parentNode.classList.add("nested-list__node--child-open");

        const parentList = nestedListElement.parentElement.parentElement.parentElement;

        if (parentList && parentList.classList.contains("nested-list")) {
            parentList.scrollTo({ top: 0 });
            parentList.classList.add("nested-list--child-open");
        }

        // repositionNestedList(nestedListElement);

        if (window.innerWidth < 992) {
            nestedListElement.style.animation = "slideIn .6s ease-in-out";
        } else {
            nestedListElement.style.animation = "slideInMD .6s ease-in-out";
        }

        listWrapper.classList.add("nested-list__wrapper--scrollbar-hide");

        nestedListElement.onanimationend = () => {
            listWrapper.classList.remove("nested-list__wrapper--scrollbar-hide");
        };

        nestedListElement.classList.add("nested-list--open");

        appendShowedListsHistory(
            nestedListElement,
            (el) => {
                const title = el.parentNode.querySelector(".nested-list__node-content").innerText;
                changeBackButtonTitle(title);
            },
        );
        const clonedNestedListElement = nestedListElement.cloneNode(true);

        const clonedContentWrapper = clonedNestedListElement.querySelector(".nested-list__items-wrapper");

        clonedContentWrapper.style.overflow = "auto";

        const deepClonedNestedLists = clonedNestedListElement.querySelectorAll(".nested-list");

        deepClonedNestedLists.forEach((listEl)=> listEl.remove());

        initNestedList(clonedNestedListElement, false);

        clonedNestedListElement.style.display = "flex";

        listWrapper.append(clonedNestedListElement);

        nestedListElement.style.display = "none";
    }

    function closeNestedList(nestedListElement) {
        if (!nestedListElement) return;

        if (!isNestedListOpen(nestedListElement)) return;

        const showedListIndex = showedListsHistory.findIndex((l) => l === nestedListElement);

        showedListsHistory.splice(showedListIndex, 1);

        nestedListElement.style.animation = "slideOut .6s ease-in-out";
        nestedListElement.classList.remove("nested-list--open");
        nestedListElement.style.display = "block";
        nestedListElement.style.translate = 0;

        function animEnd() {
            nestedListElement.style.display = null;
            nestedListElement.style.translate = null;
            nestedListElement.removeEventListener("animationend", animEnd);
        }

        nestedListElement.addEventListener("animationend", animEnd);

        listWrapper.classList.add("nested-list__wrapper--scrollbar-hide");

        const parentNode = nestedListElement.parentElement;

        const parentList = nestedListElement.parentElement.parentElement.parentElement;

        if (parentList && parentList.classList.contains("nested-list")) {
            parentList.classList.remove("nested-list--child-open");
            parentNode.classList.remove("nested-list__node--child-open");
            changeBackButtonTitle(parentList.getAttribute("data-node-title"));
        } else {
            changeBackButtonTitle(null);
        }
        listWrapper.querySelector(`.nested-list--open[data-node-id="${nestedListElement.dataset.nodeId}"]`).remove();
    }

    function closeAllNodesOnNodeLevel(nodeElement) {
        if (!nodeElement.classList.contains("nested-list__node")) return;

        const originalNode = listWrapper.querySelector(`.nested-list[data-type="root"] .nested-list__node[data-node-id="${nodeElement.dataset.nodeId}"]`);

        const parentList = originalNode.parentElement.parentElement;

        if (!parentList || !parentList.classList.contains("nested-list")) return;

        const nestedListWrapper = parentList.parentElement;

        let openedNestedLists = [];

        if (nestedListWrapper === listWrapper) {
            openedNestedLists = nestedListWrapper.querySelectorAll(".nested-list__node--collapsible .nested-list--open");
        } else {
            openedNestedLists = parentList.querySelectorAll(".nested-list__node--collapsible .nested-list--open");
        }

        if (openedNestedLists.length) {
            openedNestedLists.forEach(closeNestedList);
        }
    }

    function nodeElementClick(event) {
        const nodeElement = event.currentTarget;

        nodeElement.style.pointerEvents = "all";

        if (!nodeElement) return;

        if (!nodeElement.classList.contains("nested-list__node")) return;

        const nodeNestedList = nodeElement.querySelector(".nested-list");
        if (!nodeNestedList) return;

        if (isNestedListOpen(nodeNestedList)) {
            closeAllNodesOnNodeLevel(nodeElement);
        }
        else {
            closeAllNodesOnNodeLevel(nodeElement);
            openNestedList(nodeNestedList);
        }
    }

    /**
    *
    * @param {HTMLElement} listElement element with class "nested-list"
    */
    function initNestedList(listElement, isSetIds = true) {
        if (!listElement.classList.contains("nested-list")) return;

        const nodeElements = listElement.querySelectorAll(".nested-list__node");

        nodeElements.forEach((nodeElement, index) => {
            if (isSetIds) {
                nodeElement.dataset.nodeId = `node-id-${index}`;
            }

            const nodeNestedList = nodeElement.querySelector(".nested-list");

            if (nodeNestedList) {
                if (isSetIds) {
                    nodeNestedList.dataset.nodeId = `node-id-${index}`;
                }
                // collapsible - modifier which adds arrow icon to node item
                nodeElement.classList.add("nested-list__node--collapsible");

                const nodeContentElement = nodeElement.querySelector(".nested-list__node-content");

                if (!nodeContentElement) throw new Error('nested-list__node must contain element with class "nested-list__node-content"');

                const nodeTitle = nodeContentElement.querySelector(".nested-list__node-title");

                if (!nodeTitle) throw new Error('nested-list__node-content must contain element with class "nested-list__node-title"');

                // data-node-title - attr which the backButton can take to show level title
                nodeNestedList.setAttribute("data-node-title", nodeTitle.innerText);

                // remove nested list bubbling to node click (rm close on nested list click)
                nodeNestedList.addEventListener("click", (event) => {
                    event.stopPropagation();
                });

                // window.addEventListener("resize", () => repositionNestedList(nestedList));
            }

            if (nodeElement.classList.contains("nested-list__node--collapsible")) {
                nodeElement.addEventListener("click", (event) => {
                    if (isSetIds) {
                        nodeElementClick(event);
                    } else {
                        const originalNode = listWrapper.querySelector(`.nested-list[data-type="root"] .nested-list__node[data-node-id="${nodeElement.dataset.nodeId}"]`);
                        originalNode.click();
                        nodeElement.parentElement.querySelectorAll(".nested-list__node").forEach((nl) => {
                            if (nl !== nodeElement) {
                                nl.classList.remove("nested-list__node--child-open");
                            }
                        });
                        nodeElement.classList.toggle("nested-list__node--child-open");
                    }
                });
            }
        });
    }

    if (nestedList) {
        initNestedList(nestedList);
    }
}
