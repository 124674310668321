const BASE_URL = "https://geltek.ru";

/**
 *
 * @param {string} baseUrl
 * @returns {{
 *      get: typeof fetch,
 *      post: typeof fetch,
 *      put: typeof fetch,
 *      patch: typeof fetch,
 *      delete: typeof fetch,
 * }}
 */
export function fetchAbsolute(baseUrl) {
    /**
     *
     * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"} method
     * @returns {typeof fetch}
     */
    const methodFetch = (method) => async (url, options) => {
        const fetchWithRetries = async (url, options, retries = 3) => {
            let timeoutId = null;

            try {
                const response = await fetch(url, { ...options, method });

                const clonedResponse = response.clone();

                // Если статус не в диапазоне 200-299, выбрасываем ошибку
                if (!response.ok) {
                    throw new Error(`HTTP Error: ${response.status}`);
                }

                try {
                    const data = await response.json();
                    if (!data.success && data.errors && data.need_repeat && retries > 0) {
                        const errors = Object.values(data.errors);
                        console.warn(`
                            Retrying request 
                            cause need_repeat: true
                            ${url} (${3 - retries + 1}/3)...
                        `, errors[0]);

                        await new Promise((resolve) => {
                            timeoutId = setTimeout(resolve, 5000);
                        });

                        return fetchWithRetries(url, options, retries - 1);
                    }

                    return clonedResponse; // Успешный ответ
                } catch (error) {
                    throw new Error("Что-то пошло не так");
                }
            } catch (error) {
                if (retries > 0) {
                    console.warn(`Retrying request ${url} (${3 - retries + 1}/3)...`, error);

                    // Ждём 5 секунд перед повторной попыткой
                    await new Promise((resolve) => {
                        timeoutId = setTimeout(resolve, 5000);
                    });

                    return fetchWithRetries(url, options, retries - 1);
                }
                throw error; // Ошибка после 3 попыток
            } finally {
                // Удаляем таймер, если он был создан
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
            }
        };

        if (url.startsWith("/")) {
            return fetchWithRetries(baseUrl + url, options);
        }
        return fetchWithRetries(url, options);
    };

    return {
        get: methodFetch("GET"),
        post: methodFetch("POST"),
        put: methodFetch("PUT"),
        patch: methodFetch("PATCH"),
        delete: methodFetch("DELETE"),
    };
}

export const baseFetch = fetchAbsolute(BASE_URL);
