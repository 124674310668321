import IMask from "imask";

export function initPhoneMask() {
    const phoneMaskInputs = document.querySelectorAll("input.mask-phone");

    const phoneMaskFreeInputs = document.querySelectorAll("input.mask-phone-free");

    phoneMaskInputs.forEach((input) => {
        IMask(input, {
            mask: "+{7} 000 000 00 00",
        });
    });
    phoneMaskFreeInputs.forEach((input) => {
        IMask(input, {
            mask: [
                {
                    mask: "+7 000 000 00 00", // Первая маска с фиксированным "+7"
                    startsWith: "9",
                    lazy: false, // Префикс всегда виден
                    placeholderChar: "",
                },
                {
                    mask: "[{+}]{0} 000 000 00 00", // Вторая маска с опциональным "+"
                    lazy: true, // Убираем подчеркивания
                },
            ],
            dispatch: (appended, dynamicMasked) => {
                const value = (dynamicMasked.value + appended).replace(/\D/g, ""); // Убираем всё кроме цифр
                return value.startsWith("9") ? dynamicMasked.compiledMasks[0] : dynamicMasked.compiledMasks[1];
            },
        });
    });
}
