import { yclientsCodes } from "../../services/api/endpoints/yclients/codes.js";
import { generateCenterCardByCenterInfo } from "./center-card.js";
import { generatePopUpBySkinCenterInfo, openAppointmentPopup } from "./popup.js";
import { parseCenterInfoFromHTMLStr } from "./util.js";

const tabsWrapper = document.querySelector(".skin-diagnostic-center__map-tabs .cities-tabs");

const main = document.querySelector("main");

/**
 *
 * @param {import("../../services/api/endpoints/map/index.js").MapResponseFeature} feature
 */
function generateSkinCenterTabPanelByFeature(feature) {
    const cityTab = document.querySelector(`.cities-slider__item[data-city="${feature.city}"]`);

    if (!cityTab) return null;

    const cityTabValue = cityTab.dataset.value;

    const tabPanel = document.createElement("li");

    const {
        serviceType,
        href,
    } = parseCenterInfoFromHTMLStr(feature.properties.balloonContentBody);

    const card = generateCenterCardByCenterInfo({
        address: feature.address,
        phone: feature.phone,
        image: feature.image,
        workHours: feature.worktime,
        serviceType,
        city: feature.city,
        href,
        metro: feature.metro,
    });

    tabPanel.classList.add("tabs__tab-panel-item", "center");

    tabPanel.dataset.value = cityTabValue;

    tabPanel.append(card);

    return tabPanel;
}

/**
 *
 * @returns {{
 *  tab: HTMLElement,
 *  tabPanel:HTMLElement
 * }}
 */
function generateOnlineTab() {
    const onlineTabValue = "onlineServiceTab";

    const onlineTabItem = document.createElement("li");

    onlineTabItem.classList.add("cities-slider__item", "tabs__tab-list-item");

    onlineTabItem.dataset.value = onlineTabValue;

    onlineTabItem.dataset.city = onlineTabValue;

    onlineTabItem.dataset.center = "55.76,37.64";

    const tabButton = document.createElement("button");

    tabButton.classList.add("tabs__tab-button");

    tabButton.innerText = "Онлайн";

    onlineTabItem.append(tabButton);

    const onlineTabPanel = document.createElement("li");

    onlineTabPanel.classList.add("tabs__tab-panel-item", "center");

    onlineTabPanel.dataset.value = onlineTabValue;

    const card = generateCenterCardByCenterInfo({
        address: "",
        phone: "+7-985-840-08-12",
        image: "",
        workHours: [
            { period: "пн-пт", time: "с 11:00 до 19:00" },
            { period: "сб", time: "с 11:00 до 20:00" },
            { period: "вс", time: "с 10:00 до 20:00" },
        ],
        serviceType: "online",
        city: "",
        href: "",
        metro: "",
    });

    onlineTabPanel.append(card);

    return {
        tab: onlineTabItem,
        tabPanel: onlineTabPanel,
    };
}

/**
 *
 * @param {import("../../services/api/endpoints/map/index.js").MapResponse} geoPoints
 */
export async function initTabsCards(geoPoints) {
    const tabList = tabsWrapper.querySelector(".cities-slider__wrapper");
    const tabPanelsList = tabsWrapper.querySelector(".tabs__tab-panel-list");

    geoPoints.points.features.forEach((feature, i) => {
        const tabPanelHtml = generateSkinCenterTabPanelByFeature(feature);

        if (tabPanelHtml && i === 0) {
            tabPanelHtml.classList.add("tabs__tab-panel-item--show");
        }

        if (tabPanelHtml) {
            tabPanelsList.append(tabPanelHtml);
        }
    });

    const { tab, tabPanel } = generateOnlineTab();

    tabList.append(tab);

    tabPanelsList.append(tabPanel);

    const popup = generatePopUpBySkinCenterInfo({
        address: "",
        phone: "+7-985-840-08-12",
        city: "",
        companyId: yclientsCodes.online.companyId,
        serviceId: yclientsCodes.online.serviceId,
        serviceType: "online",
        workHours: "",
    });

    main.append(popup);

    Object.keys(yclientsCodes).forEach((serviceType) => {
        const appointmentButtons = document.querySelectorAll(`[data-appointment="${serviceType}"]`);

        appointmentButtons.forEach((appointmentButton) => {
            const clonedAppointmentButton = appointmentButton.cloneNode(true);

            appointmentButton.replaceWith(clonedAppointmentButton);
            clonedAppointmentButton.addEventListener("click", () => openAppointmentPopup(serviceType));
        });
    });
}
